import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40e142a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["inert"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["page-shadow", { open: _ctx.open }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-shadow')))
    }, null, 2),
    _createElementVNode("div", _mergeProps({
      class: ["dialog", { open: _ctx.open, mini: _ctx.mini }],
      inert: !_ctx.open
    }, _ctx.$attrs), [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 16, _hoisted_1)
  ], 64))
}