
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Dialog',
  props: {
    mini: Boolean,
    open: Boolean,
  },
});
