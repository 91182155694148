
import { defineComponent } from 'vue';
import IconButton from '@/components/ui/IconButton.vue';
import Button from '../../components/ui/Button.vue';
import { mapActions, mapState } from 'pinia';
import { useStore } from '@/store';
import { ServerState } from '@shared/types';
import Dialog from '@/components/ui/Dialog.vue';

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};
const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    return new Promise((resolve) => {
      fallbackCopyTextToClipboard(text);
      resolve(undefined);
    });
  }
  return navigator.clipboard.writeText(text);
};

export default defineComponent({
  name: 'Settings',
  components: {
    IconButton,
    Button,
    Dialog,
  },
  data: () => ({
    json: {} as ServerState | null,
    newPlayerModalShown: false,
    newPlayerName: '',
    editPlayerNameModalShown: false,
    editPlayerName: '',
    editPlayerId: '',
    removePlayerModalShown: false,
    removePlayerId: '',
    copyLinkModalShown: false,
    copyLinkModalTitle: '',
    copyLinkUrl: '',
    debuggingConfigmationShown: false,
  }),
  computed: {
    ...mapState(useStore, ['serverState']),
    ...mapState(useStore, {
      players(store) {
        if (!store.serverState) return;

        return store.serverState.players;
      },
    }),
    inspectorUrl() {
      return window.location.origin;
    },
  },
  methods: {
    ...mapActions(useStore, [
      'addPlayer',
      'changePlayerName',
      'changePlayerBroke',
      'removePlayer',
      'setServerState',
    ]),
    showNewPlayerModal() {
      this.newPlayerModalShown = true;
      setTimeout(() => {
        document.querySelector<HTMLElement>('#new-player-name')?.focus();
      });
    },
    closeNewPlayerModal() {
      this.newPlayerName = '';
      this.newPlayerModalShown = false;
    },
    addPlayerFromModal() {
      this.addPlayer(this.newPlayerName);
      this.closeNewPlayerModal();
    },
    showEditPlayerNameModal(playerId: string) {
      if (
        !this.serverState ||
        !Object.keys(this.serverState.players).includes(playerId)
      )
        return;

      this.editPlayerId = playerId;
      this.editPlayerName = this.serverState.players[playerId].name;
      this.editPlayerNameModalShown = true;

      setTimeout(() => {
        document.querySelector<HTMLElement>('#edit-player-name')?.focus();
      });
    },
    closeEditPlayerNameModal() {
      this.editPlayerNameModalShown = false;
    },
    changePlayerNameFromModal() {
      this.changePlayerName(this.editPlayerId, this.editPlayerName);
      this.closeEditPlayerNameModal();
    },
    async copyLink(link: string, title: string) {
      this.copyLinkUrl = link;
      this.copyLinkModalTitle = title;
      await copyTextToClipboard(link);
      this.copyLinkModalShown = true;
    },
    async copyPlayLink(playerId: string) {
      const playerName = this.players?.[playerId]?.name;
      const playLink = `${this.inspectorUrl}/play/${playerId}`;
      this.copyLink(playLink, `Spiellink „${playerName}“`);
    },
    closeCopyLinkModal() {
      this.copyLinkModalShown = false;
    },
    setPlayerBroke(playerId: string, broke: boolean) {
      this.changePlayerBroke(playerId, broke);
    },
    showRemovePlayerModal(playerId: string) {
      this.removePlayerId = playerId;
      this.removePlayerModalShown = true;
    },
    closeRemovePlayerModal() {
      this.removePlayerModalShown = false;
    },
    removePlayerFromModal() {
      this.removePlayer(this.removePlayerId);
      this.closeRemovePlayerModal();
    },
    changeJson(event: Event) {
      const target = event.target as HTMLTextAreaElement;
      const rawValue = target.value;
      try {
        const value = JSON.parse(rawValue);
        this.json = value;
      } catch (error) {
        this.json = { ...this.json } as ServerState | null;
      }
    },
    showDebuggingConfirmation() {
      this.debuggingConfigmationShown = true;
    },
    closeDebuggingConfirmation() {
      this.debuggingConfigmationShown = false;
    },
    sendDebuggingServerState() {
      this.closeDebuggingConfirmation();
      if (!this.json) return;

      this.setServerState(this.json);
    },
    logout() {
      sessionStorage.removeItem('admin-password');
      this.$router.push('/');
    },
  },
  watch: {
    serverState: {
      handler(newServerState) {
        this.json = newServerState;
      },
      immediate: true,
    },
  },
});
